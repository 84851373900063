import React, { useState, useEffect } from 'react';

import img1Mobile from '../../assets/images/slider/1.320.jpg';
import img2Mobile from '../../assets/images/slider/2.320.jpg';
import img3Mobile from '../../assets/images/slider/3.320.jpg';
import img4Mobile from '../../assets/images/slider/4.320.jpg';
import img5Mobile from '../../assets/images/slider/5.320.jpg';
import img6Mobile from '../../assets/images/slider/6.320.jpg';
import img7Mobile from '../../assets/images/slider/7.320.jpg';
import img8Mobile from '../../assets/images/slider/8.320.jpg';
import img9Mobile from '../../assets/images/slider/9.320.jpg';
import img10Mobile from '../../assets/images/slider/10.320.jpg';
import img1Desktop from '../../assets/images/slider/1.jpg';
import img2Desktop from '../../assets/images/slider/2.jpg';
import img3Desktop from '../../assets/images/slider/3.jpg';
import img4Desktop from '../../assets/images/slider/4.jpg';
import img5Desktop from '../../assets/images/slider/5.jpg';
import img6Desktop from '../../assets/images/slider/6.jpg';
import img7Desktop from '../../assets/images/slider/7.jpg';
import img8Desktop from '../../assets/images/slider/8.jpg';
import img9Desktop from '../../assets/images/slider/9.jpg';
import img10Desktop from '../../assets/images/slider/10.jpg';

const Slider = () => {
  const imageMobileArray = [
    img1Mobile,
    img2Mobile,
    img3Mobile,
    img4Mobile,
    img5Mobile,
    img6Mobile,
    img7Mobile,
    img8Mobile,
    img9Mobile,
    img10Mobile,
  ];
  const imageDesktopArray = [
    img1Desktop,
    img2Desktop,
    img3Desktop,
    img4Desktop,
    img5Desktop,
    img6Desktop,
    img7Desktop,
    img8Desktop,
    img9Desktop,
    img10Desktop,
  ];
  const [arrayIndex, setArrayIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [intervalId, setIntervalId] = useState(null);

  const updateImages = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1000) {
      setImages(imageDesktopArray);
    } else if (screenWidth <= 1000) {
      setImages(imageMobileArray);
    }
  };

  const onClick = (index) => {
    clearInterval(intervalId);
    setArrayIndex(index);
    setIntervalId(
      setInterval(() => {
        setArrayIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 1500)
    );
  };

  useEffect(() => {
    updateImages();
    clearInterval(intervalId); // Wyczyść interwał przed ustawieniem nowego

    const newIntervalId = setInterval(() => {
      setArrayIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 1500);

    setIntervalId(newIntervalId);

    return () => {
      clearInterval(newIntervalId); // Wyczyść interwał w funkcji zwrotnej
    };
    // eslint-disable-next-line
  }, [images.length]);

  return (
    <div className='slider'>
      <img
        className='slider__images'
        src={images[arrayIndex]}
        alt='sliderImages'
      />
      <div className='slider__dots'>
        {images.map((_, index) => (
          <span
            key={index}
            className={`slider__dot ${
              index === arrayIndex ? 'slider__dot--active' : ''
            }`}
            onClick={() => onClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Slider;
