const FilmsList = () => {
  const films = [
    {
      title: 'Łowisko Barcin konkurs Method Mania Live - Trotka',
      description: 'LIVE sklepu TROTKA z Team-owiczami naszego Łowiksa Barcin.',
      link: 'https://www.youtube.com/embed/2RX2PzsDqVQ',
    },
    {
      title: 'Karpie spod drugiego brzegu',
      description:
        'KARPIE spod DRUGIEGO BRZEGU | Adam Niemiec Jak co piątek czas na kolejny film! Tym razem wspólnie z Adamem Niemcem zabieramy Was na łowisko BARCIN. Będziemy "testować" najnowsze kije Monster Xtreme Distanca na karpiach :)',
      link: 'https://www.youtube.com/embed/c2gnqjgKpnc?si=Sm4g7l2UeJi97EWn',
    },
    {
      title: 'Podstawowe zanęty i przynęty do metody',
      description:
        'Adam Niemiec, Podstawowe ZANĘTY i PRZYNĘTY do METODY / METHOD FEEDER | Zapraszamy na pierwszy film na kanale SONUBAITS POLSKA! W tym filmie Adam Niemiec przedstawi wam swoje podstawowe zanęty i przynęty do łowienia na method feeder prosto z łowiska Barcin.',
      link: 'https://www.youtube.com/embed/0eihOr5MDKs?si=m7gKBHHPEQuywsP1',
    },
    {
      title: 'Jesienny dzień z Method Feeder',
      description:
        'OSMO FISHING czyli Dawid Moska i Radosław Krawczuk goszczą na łowisko Barcin. Łowimy skutecznie w zimnej wodzie, sama zanęta odpaliła - Jesienny dzień z Method Feeder',
      link: 'https://www.youtube.com/embed/yO_XUOaoSlU?si=uroqWmItRKvKmHfe',
    },
    {
      title: 'MoonfinTV - Nowe Łowiska',
      description:
        'Artur Matczak wraz z Kamil Kwocz na łowisku Barcin!!!. #1 Nowe Łowisko - Method Feeder. Dzisiejszy odcinek to można by rzec, rozpoczęcie cyklu naszych podróży w Wasze miejsca! Chcielibyśmy co jakiś czas, sukcesywnie wybierać się w Wasze strony aby odwiedzić łowiska, których nie znamy ! Piszcie w komentarzach gdzie możemy zrealizować kolejny film !',
      link: 'https://www.youtube.com/embed/qfeK9i8eHl4?si=hBXwPrv29b4H-EPo',
    },
    {
      title: 'Znajdź łowisko - Barcin',
      description: 'Łowisko Barcin - rybne łowisko niedaleko Bydgoszczy',
      link: 'https://www.youtube.com/embed/h7I-MS6Zo00?si=vSTK5ZCIZJtbK5FN',
    },
    {
      title: 'Meeting Trotka&Przyjaciele',
      description:
        'Witajcie, Zapraszamy na film z Meetingu Trotka&Przyjaciele na Łowisku Barcin. Ania wspólnie z Bartkiem zorganizowali meeting specjalny, gdzie główną nagrodą był bilet na MFP Masters 2023! Bartek zaprezentował również nagrody marki Frenzee, oraz przybliżył formułę meetingów.',
      link: 'https://www.youtube.com/embed/gN8V7hulZtQ?si=aaJab9AHs7HE6kMW',
    },
    {
      title: 'Prima Methoda - relacja',
      description:
        'Zapraszamy na relację z zawodów "Prima Methoda", które zostały rozegrane 1 kwietnia 2023 na Łowisku Barcin, w województwie Kujawsko-Pomorskim. W zawodach wzięło udział 40 osób, podzielonych na 4 sektory. Łącznie zawodnicy złowili ponad 400kg ryb i wytrwali do końca pomimo niesprzyjającej pogody. Gratulacje dla zwycięzców i wszystkich uczestników!',
      link: 'https://www.youtube.com/embed/1cCOD2Ef_WY?si=tANAv3gDqdXhrbei',
    },
    {
      title: 'Ciekawe łowiska BARCIN',
      description: 'Łowisko Barcin na kanale WNS YOUTUBE - Ciekawe Łowiska #7.',
      link: 'https://www.youtube.com/embed/HU3GC9MRj9s?si=XEbtSsrz658tgMYG',
    },
    {
      title: 'Jesienny METHOD FEEDER na wesoło',
      description:
        'Jesień to czas kiedy pogoda potrafi już mocno zaskoczyć. Jednak udało się wybrać na fajna wodę i trafić piękna słoneczna pogodę. Myślę ze jak na ta porę roku wyjazd udany co możecie zobaczyć na filmie. Spotkaliśmy sporo znajomych i pozytywnie zakręconych wędkarzy, którym pogoda nie była straszna Udało się sprawdzić nowe przynęty od Robinsona, które są już dostępne w sprzedaży.',
      link: 'https://www.youtube.com/embed/kUehOVq8Zak?si=gk7-YyfKtPfGs1T7',
    },
  ];

  return { films };
};

export default FilmsList;
