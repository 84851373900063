import React from 'react';

const Points = () => {
  const statuteItems = [
    {
      title:
        'Warunkiem korzystania z łowiska jest zapoznanie się z regulaminem i stosowanie się do niego.',
      subpoints: [],
    },
    {
      title:
        'Łowisko Barcin czynne jest przez całą dobę, także w weekendy oraz święta.',
      subpoints: [],
    },
    {
      title:
        'Nocka zaczyna się od godziny 18:00, a kończy o 10:00 następnego dnia. Dzień od 6:00. (Godziny przyjazdu można uzgodnić z właścicielem łowiska).',
      subpoints: [],
    },
    {
      title:
        'Cennik opłat znajduje się u właściciela i innych osób obsługujących. Cennik 2024:',
      subpoints: [
        'Łowienie dzienne 50zł',
        'Łowienie nocne 60zł',
        'Doba 90zł',
        'Proponujemy również wykupienie zezwolenia na cały rok w kwocie 1000zł ( wyłączone z łowienia dni, w których odbywać się będą zawody )',
        'Wynajem łowiska pod zawody: Cała woda 2000zł, Jeden brzeg 1000zł',
      ],
    },
    {
      title: 'Dopuszczalne są dwie wędki/1 osobę.',
      subpoints: [],
    },
    {
      title: 'Zakaz zabierania ryb.',
      subpoints: [],
    },
    {
      title:
        'W jednej siatce można przechowywać max 10 ryb. Prośba, aby nie trzymać ryb w siatce dłużej jak 4 godziny.',
      subpoints: [],
    },
    {
      title: 'Dopuszczalne są dwie wędki/1 osobę.',
      subpoints: [],
    },
    {
      title:
        'Wymagane jest posiadanie: podbieraka, maty typu „wanna” bądź „kołyska”, środka do dezynfekcji.',
      subpoints: [],
    },
    {
      title: 'Zakaz używania plecionek oraz haczyków z zadziorem.',
      subpoints: [],
    },
    {
      title:
        'Wędkujący może przebywać na łowisku z osobami towarzyszącymi, po zgłoszeniu u gospodarza czy opiekuna łowiska.',
      subpoints: [],
    },
    {
      title:
        'Osoba wędkująca ponosi również odpowiedzialność za osoby przebywające na danym stanowisku, za bezpieczeństwo oraz za poinformowanie ich o panujących na łowisku regulaminie, opłatach, karach za niestosowanie się do regulaminu.',
      subpoints: [],
    },
    {
      title:
        'Obsługa nie odpowiada za wypadki losowe osób przebywających na łowisku.',
      subpoints: [],
    },
    {
      title:
        'Każdy wędkarz odpowiada za swój sprzęt wędkarski czy rzeczy pozostawione na stanowisku.',
      subpoints: [],
    },
    {
      title:
        'Osoby poniżej 18 roku życia mogą wędkować tylko z pełnoletnim opiekunem.',
      subpoints: [],
    },
    {
      title:
        'Zabronione jest korzystanie ze środków pływających typu ponton, łódka.',
      subpoints: [],
    },
    {
      title:
        'Zabrania się wrzucania do wody odpadków i niedopałków papierosów, pozostałości po grillach. Należy również dbać o porządek na danym stanowisku, nie niszczyć zieleni itp. Śmieci zabieramy ze sobą.',
      subpoints: [],
    },
    {
      title:
        'Gospodarz łowiska i inne osoby obsługujące mają prawo do: -kontroli samochodów i bagaży, w przypadku podejrzenia o kradzieży ryb w asyście Policji -wypraszania z terenu łowiska, w przypadku: głośnego zachowania, śmiecenia, dewastacji i kradzieży. W wyżej wymienionych przypadkach opłata za korzystanie z łowiska nie będzie zwracana.',
      subpoints: [],
    },
    {
      title:
        'Próba kradzieży lub nieprzestrzeganie regulaminu wyklucza możliwość ponownego odwiedzania łowiska. Osoba złapana na kradzieży podlega karze pieniężnej 1000zł/kg.',
      subpoints: [],
    },
    {
      title:
        'Podczas pobytu na łowisku obowiązuje zakaz przemieszczania się samochodem, w przypadkach łamania zakazu pojazdy będą zaparkowane na parkingu, a nie przy stanowiskach.',
      subpoints: [],
    },
    {
      title:
        'Wędkarze łowiący na łowisku wyrażają zgodę na udostępnienie i publikację zdjęć złowionych przez nich ryb.',
      subpoints: [],
    },
    {
      title: 'Właściciel zastrzega sobie prawo do zmiany regulaminu.',
      subpoints: [],
    },
  ];

  const renderedPoints = statuteItems.map((item, index) => (
    <li className='statutes__item' key={index}>
      {item.title}
      {item.subpoints.length > 0 && (
        <ul>
          {item.subpoints.map((subpoint, subIndex) => (
            <li className='statutes__subpoints' key={subIndex}>
              {subpoint}
            </li>
          ))}
        </ul>
      )}
    </li>
  ));

  return <ol className='statutes__list'>{renderedPoints}</ol>;
};

export default Points;
