import React from 'react';

const Links = () => {
  const FBlink = 'https://www.facebook.com/profile.php?id=100066480673142';
  const IGLink = 'https://www.instagram.com/lowisko_barcin/';
  const tiktokLink = 'https://www.tiktok.com/@lowisko_barcin';
  const telLink = 'tel:+48501515463';
  const mailLink = 'mailto:lowisko_barcin@interia.pl';
  const facebook = 'facebook';
  const IG = 'instagram';
  const tiktok = 'tiktok';
  const tel = '501-515-463';
  const mail = 'lowisko_barcin@interia.pl';

  return (
    <>
      <div className='links__facebook contact__link'>
        <i class='fa-brands fa-square-facebook contact__link'></i>
        <a href={FBlink} className='contact__link'>
          {facebook}
        </a>
      </div>
      <div className='links__instagram contact__link'>
        <i class='fa-brands fa-square-instagram contact__link'></i>
        <a href={IGLink} className='contact__link'>
          {IG}
        </a>
      </div>
      <div className='links__tiktok contact__link'>
        <i class='fa-brands fa-tiktok contact__link'></i>
        <a href={tiktokLink} className='contact__link'>
          {tiktok}
        </a>
      </div>
      <div className='links__mobile contact__link'>
        <i class='fa-solid fa-phone contact__link'></i>
        <a href={telLink} className='contact__link'>
          {tel}
        </a>
      </div>
      <div className='links__mail contact__link'>
        <i class='fa-regular fa-envelope contact__link'></i>
        <a href={mailLink} className='contact__link'>
          {mail}
        </a>
      </div>
    </>
  );
};

export default Links;
