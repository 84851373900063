import React from 'react';
import Links from '../../components/home/Links';

import partner1 from '../../assets/images/partners/extruFish.jpg';
import partner2 from '../../assets/images/partners/feederBait.png';
import partner3 from '../../assets/images/partners/feenyx.png';
import partner4 from '../../assets/images/partners/fotografia.jpg';
import partner5 from '../../assets/images/partners/guru.jpg';
import partner6 from '../../assets/images/partners/matchpro.jpg';
import partner7 from '../../assets/images/partners/osmo.png';
import partner8 from '../../assets/images/partners/trotka.jpg';
import partner9 from '../../assets/images/partners/znajdzLowisko.png';

const Contact = () => {
  const partnersArray = [
    partner1,
    partner2,
    partner3,
    partner4,
    partner5,
    partner6,
    partner7,
    partner8,
    partner9,
  ];

  const image = partnersArray.map((image, index) => {
    return (
      <img
        key={index}
        src={image}
        alt={`partners${index}`}
        className='contact__image'
      />
    );
  });

  const subtitle = 'kontakt';
  const text =
    'Serdecznie zapraszamy do kontaktu z nami poprzez podane obok formy. Jeżeli chcecie sprawdzić warunki panujące aktualnie na łowisku, zapytać o wolne terminy, jesteśmy do waszej dyspozycji. Zapraszamy również do aktywnego uczestnictwa na naszym profilu facebook.';
  return (
    <div className='contact'>
      <h2 className='home__subtitle'>{subtitle}</h2>
      <p className='home__text'>{text}</p>
      <div className='contact__partners'>{image}</div>
      <div className='contact__links'>
        <Links />
      </div>
    </div>
  );
};

export default Contact;
