import React from 'react';
import { Link } from 'react-router-dom';

import Slider from './Slider';

const About = () => {
  const aboutSubtitle = 'nasze łowisko';
  const socialSubtitle = 'jesteśmy na social mediach';
  const aboutText =
    'Łowisko Barcin, jest to staw o powierzchni około 3ha znajdujący się w miejscowości Barcin w województwie kujawsko-pomorskim. Dominującą rybą w naszym stawie jest karp, ale występują również takie gatunki jak lin, karaś, amur. Miejsce stworzone z pasji do wędkarstwa zyskuje coraz więcej przywiązanych wędkarzy, nie tylko z zawodniczym podejściem łowienia, ale również chcących nad wodą odpocząć od codziennych obowiązków, którzy wspólnie tworzą wspaniałą atmosferę na łowisku.';
  const FBLink =
    'https://www.facebook.com/people/%C5%81owisko-Barcin/100066480673142/?locale=hi_IN&paipv=0&eav=AfbjhZ-3JaNyCGXxrdGQZKIkDHrQfWJ9Dxz1s9NSHwcXMsZzbXE800HlsZyFWMz0pQA&_rdr';
  const IGLink = 'https://www.instagram.com/lowisko_barcin/';
  const tiktokLink = 'https://www.tiktok.com/@lowisko_barcin';
  const socialsTextFirstPart = `Chcesz być na bieżąco z najnowszymi informacjami, zawodami organizowanymi na naszym łowisku? Koniecznie odwiedź nasz profil na Facebooku `;
  const socialsTextSecondPart = `, Instagramie, `;
  const socialsTextThirdPart = ' oraz ';
  const socialsTextFourthPart =
    '. Sprawdź również produkcje filmowe, które powstały na naszym łowisku w zakładce YouTube.';
  return (
    <>
      <div className='about'>
        <div className='home__text about__text'>
          <h2 className='home__subtitle'>{aboutSubtitle}</h2>
          <p className='home__text '>{aboutText}</p>
          <h2 className='home__subtitle'>{socialSubtitle}</h2>
          <p className='home__text '>
            {socialsTextFirstPart}
            <Link to={FBLink} className='about__link'>
              Łowisko Barcin
            </Link>
            {socialsTextSecondPart}
            <Link to={IGLink} className='about__link'>
              lowisko_barcin
            </Link>
            {socialsTextThirdPart}
            <Link to={tiktokLink} className='about__link'>
              tiktok-u
            </Link>
            {socialsTextFourthPart}
          </p>
        </div>
        <div className='about__slider'>
          <Slider />
        </div>
      </div>
    </>
  );
};

export default About;
