import React from 'react';
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

const Event = ({ images, thumb }) => {
  const imagePaths = images.keys().map(images);
  const thumbPaths = thumb.keys().map(thumb);

  const photo = imagePaths.map((image, index) => (
    <a key={index} href={image}>
      <img
        key={index}
        src={thumbPaths[index]}
        alt={`img${index}`}
        style={{ width: '100px', margin: '5px' }}
      />
    </a>
  ));
  return (
    <>
      <LightGallery speed={500} plugins={[lgThumbnail, lgZoom]}>
        {photo}
      </LightGallery>
    </>
  );
};

export default Event;
