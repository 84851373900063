import React from 'react';

const Competition = () => {
  const competitonsText =
    'W bieżącym roku przygotowujemy dla Was sporą ilość zawodów. Zawody organizowane wraz z czołowmi producentami, sklepami z branży wędkarskiej dostarczą z pewnością wszystkim sporą dawkę rozrywki. W tym roku zawody na naszym łowisku organizują takie marki jak: ISIKA, TROTKA, MEUS, FEEDER BAIT, SALON WĘDKARSKI OSINIEC. Jak co roku oczywiście organizujemy dla Was zawody o PUCHAR ŁOWISKA BARCIN jak również planujemy pod koniec sezonu organizować lubiane przez Miłośników naszego łowiska koleżeńskie zawody do CZAPY, a gratką dla najwytrwalszych będzie zdobycie Pucharu Łowiska Barcin. Zawody będą organizowane jako zawody dzienne, ale również nie zabraknie tych nocnych. Jeżeli chcesz zapoznać się z dokładnym terminarzem zawodów lub sprawdzić czy jest wolny termin dla zawodów, które chcesz zorganizować na naszym łowisku zapraszamy do linku niżej!';
  return (
    <>
      <h2 className='home__subtitle'>Zawody</h2>
      <p className='home__text'>{competitonsText}</p>
    </>
  );
};

export default Competition;
