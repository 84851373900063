import React from 'react';
import FilmsList from './FilmsList';

const { films } = FilmsList();
const film = films.map((film) => (
  <div className='yt__film'>
    <iframe
      className='yt__player'
      src={film.link}
      frameborder='0'
      title='YouTube video player'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
      allowfullscreen
    />
    <h3 className='yt__titleVideo'>{film.title}</h3>
    <span className='yt__description'>{film.description}</span>
  </div>
));

const Films = () => {
  return <div className='yt__films'>{film}</div>;
};

export default Films;
