import React from 'react';
import Event from '../Event';

const images = require.context(
  '../../../assets/images/gallery/2023/barcinNight2023/photo/',
  false,
  /\.(jpg)$/
);
const thumb = require.context(
  '../../../assets/images/gallery/2023/barcinNight2023/thumb/',
  false,
  /\.(jpg)$/
);
const PucharBarcin = () => {
  return (
    <>
      <Event images={images} thumb={thumb}></Event>
    </>
  );
};

export default PucharBarcin;
