import React from 'react';

const Statute = () => {
  const firstStatueText =
    'Chcesz nas odwiedzić? Koniecznie zapoznaj się z regulaminem Łowiska Barcin. W skrócie na łowisku obowiązują zasady:';
  const statuteArray = [
    'cena: 50zł dzień/60zł noc (dwie wędki);',
    'dbamy o naszych podwodnych przyjaciół, stosujemy haczyki bezzadziorowe oraz nie stosujemy plecionek jako linki głównej;',
    "nie zabieramy ryb, łowisko 'no kill';",
    'dbamy o porządek wokół swojego stanowiska;',
    'łowisko czynne 24h/7, 365dni w roku.',
  ];
  const li = statuteArray.map((item, index) => {
    return (
      <li key={index} className='statute__items'>
        {item}
      </li>
    );
  });
  const secondStatuteText = <ul className='statute__list'>{li}</ul>;
  const thirdStatuteText =
    'Szczegółowy regulamin znajduje się w zakładce regulamin lub w linku poniżej.';
  return (
    <div className='statute'>
      <h2 className='home__subtitle'>Regulamin</h2>
      <span className='home__text'>
        {firstStatueText}
        {secondStatuteText}
        {thirdStatuteText}
      </span>
    </div>
  );
};
export default Statute;
