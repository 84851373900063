import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import StartPage from '../pages/StartPage';
import MobileNav from '../components/mobileNav/MobileNav';
import DesktopNav from '../components/desktopNav/DesktopNav';
import Ticker from '../pages/Ticker';
import Pages from '../layout/Pages';
import Footer from '../components/footer/Footer';

import '../styles/index.scss';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

const App = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isScrolled, setIsScrolled] = useState(false);
  const [addClassToStartPage, setAddClassToStartPage] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    const handleStartPage = () => {
      setTimeout(() => {
        setAddClassToStartPage(true);
      }, 2000);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    handleStartPage();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const handleScroll = () => {
    window.scrollTo(0, 0);
  };
  const nav = () => {
    return windowWidth > 800 && isScrolled ? (
      <DesktopNav handleScroll={handleScroll} />
    ) : null;
  };

  const addClass = () => {
    return isScrolled ? 'navDesktop' : '';
  };

  return (
    <Router>
      <aside className={`startPage ${` ${addClass ? 'deactive' : ''}`}`}>
        <StartPage addClass={addClassToStartPage} />
      </aside>
      <nav className={windowWidth > 800 ? addClass() : 'navMobile'}>
        {windowWidth > 800 ? nav() : <MobileNav handleScroll={handleScroll} />}
      </nav>
      {windowWidth > 800 && isScrolled && <Ticker />}
      {windowWidth <= 800 && <Ticker />}

      <main className='pages'>
        <Pages />
      </main>
      <footer className='footer'>
        <Footer />
      </footer>
    </Router>
  );
};

export default App;
