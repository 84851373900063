import React from 'react';
import Event from '../Event';
// import LightGallery from 'lightgallery/react';
// import lgThumbnail from 'lightgallery/plugins/thumbnail';
// import lgZoom from 'lightgallery/plugins/zoom';

const images = require.context(
  '../../../assets/images/gallery/2022/lato2022/photo/',
  false,
  /\.(jpg)$/
);
const thumb = require.context(
  '../../../assets/images/gallery/2022/lato2022/thumb/',
  false,
  /\.(jpg)$/
);
// const imagePaths = images.keys().map(images);
// const thumbPaths = thumb.keys().map(thumb);

// const photo = imagePaths.map((image, index) => (
//   <a key={index} href={image}>
//     <img
//       key={index}
//       src={thumbPaths[index]}
//       alt={`img${index}`}
//       style={{ width: '100px' }}
//     />
//   </a>
// ));
const PozegnanieLata = () => {
  return (
    <>
      {/* <LightGallery speed={500} plugins={[lgThumbnail, lgZoom]}>
        {photo}
      </LightGallery> */}
      <Event images={images} thumb={thumb}></Event>
    </>
  );
};

export default PozegnanieLata;
