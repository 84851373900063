import React from 'react';

const Gallery = () => {
  const subtitle = 'galeria';
  const text =
    'Odwiedź naszą galerię i zobacz fotorelację z zawodów odbytych na naszym łowisku. Jeżeli jeszcze nie odwiedzałeś naszego łowiska możesz zapoznać się z jego wyglądem, zobaczyć jakie ryby można u nas złowić oraz poczuć chociaż mały procent klimatu jaki panuje na naszym łowisku. Zapraszamy do fotograficznej podróży w czasie, link poniżej lub w menu głównym. DO ZOBACZENIA NAD WODĄ!!!';
  return (
    <div className='gallery__home'>
      <h2 className='home__subtitle'>{subtitle}</h2>
      <p className='home__text'>{text}</p>
    </div>
  );
};

export default Gallery;
