import React from 'react';
import Marquee from 'react-fast-marquee';

const Ticker = () => {
  const textFirst = 'ŁOWISKO CZYNNE!!!  ';
  const textSecond = '  Sprawdź terminarz zawodów';
  const textThird = '';

  return (
    <div className='ticker'>
      <Marquee speed={30} autoFill={true}>
        <span className='ticker__span'>{textFirst}</span>
        <span className='ticer__span'>{textSecond}</span>
        <span className='ticer__span'>{textThird}</span>
      </Marquee>
    </div>
  );
};

export default Ticker;
