import React from 'react';
import Table from 'react-bootstrap/Table';

import CupResults from '../components/cup/CupResults';

const Cup = () => {
  const { players } = CupResults();

  const sumBestThree = (player) => {
    const weights = [
      parseFloat(player.startSezon) || 0,
      parseFloat(player.springCup) || 0,
      parseFloat(player.pairCup) || 0,
      parseFloat(player.nightCup) || 0,
      parseFloat(player.summerBye) || 0,
    ];
    weights.sort((a, b) => b - a);
    const sum = weights.slice(0, 3).reduce((sum, value) => sum + value, 0);
    return Number(sum).toFixed(2); // Zaokrąglanie sumy do dwóch miejsc po przecinku
  };

  players.sort((a, b) => sumBestThree(b) - sumBestThree(a));

  const tableRows = players.map((player, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{player.name}</td>
        <td>{player.startSezon}</td>
        <td>{player.springCup}</td>
        <td>{player.pairCup}</td>
        <td>{player.nightCup}</td>
        <td>{player.summerBye}</td>
        <td>{sumBestThree(player)}</td>
      </tr>
    );
  });

  return (
    <div className='results'>
      <h2 className='results__title'>Wyniki Pucharu Łowiska Barcin</h2>
      <div className='results__table'>
        <Table responsive striped bordered hover variant='dark'>
          <thead>
            <tr>
              <th>LP</th>
              <th>Imię</th>
              <th>7.04</th>
              <th>18/19.05</th>
              <th>6/7.07</th>
              <th>3/4.08</th>
              <th>29.09</th>
              <th>Suma 3 najlepszych</th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </Table>
      </div>
    </div>
  );
};

export default Cup;
