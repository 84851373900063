import React from 'react';

import Start from '../components/home/Start';
import About from '../components/home/About';
import Competition from '../components/home/Competittion';
import Statute from '../components/home/Statue';
import Gallery from '../components/home/Gallery';
import Contact from '../components/home/Contact';

const Home = () => {
  return (
    <div className='home'>
      <div className='home__start'>
        <Start />
      </div>
      <div className='home__bgc'>
        <div className='home__about'>
          <About />
        </div>
        <div className='home__competitions'>
          <Competition />
        </div>
        <div className='home__statute'>
          <Statute />
        </div>
        <div className='home__gallery'>
          <Gallery />
        </div>
        <div className='home__contact'>
          <Contact />
        </div>
        <div className='home__partners'></div>
      </div>
    </div>
  );
};

export default Home;
