import React from 'react';
import { NavLink } from 'react-router-dom';

const DesktopNavList = ({ handleScroll }) => {
  const elementsNav = [
    { name: 'home', path: '/' },
    { name: 'zawody', path: '/zawody' },
    { name: 'Puchar Łowiska', path: '/wynikiPucharŁowiska' },
    { name: 'regulamin', path: '/regulamin' },
    { name: 'galeria', path: '/galeria' },
    { name: 'youtube', path: '/youtube' },
    { name: 'facebook', path: '/facebook' },
    { name: 'instagram', path: '/instagram' },
    { name: 'tiktok', path: '/tiktok' },
  ];

  const navElement = elementsNav.map((element) => (
    <li className='navDesktop__item' key={element.name}>
      <NavLink
        className='navDesktop__link'
        to={element.path}
        onClick={() => {
          handleScroll();
        }}
      >
        {element.name}
      </NavLink>
    </li>
  ));

  return <ul className='navDesktop__items'>{navElement}</ul>;
};

export default DesktopNavList;
