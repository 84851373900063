import React from 'react';
import Films from '../components/yt/Films';
const Yt = () => {
  return (
    <div className='yt'>
      <h2 className='yt__title'>Łowisko Barcin w produkcjach filmowych</h2>
      <Films />
    </div>
  );
};

export default Yt;
