import React from 'react';
import { NavLink } from 'react-router-dom';

const MobileNavList = ({ handleClick, handleScroll }) => {
  const elementsNav = [
    { name: 'home', path: '/' },
    { name: 'zawody', path: '/zawody' },
    { name: 'Puchar Łowiska', path: '/wynikiPucharŁowiska' },
    { name: 'regulamin', path: '/regulamin' },
    { name: 'galeria', path: '/galeria' },
    { name: 'youtube', path: '/youtube' },
    {
      name: 'facebook',
      path: 'https://www.facebook.com/people/%C5%81owisko-Barcin/100066480673142/?locale=hi_IN&paipv=0&eav=AfaF7g23Ta4DTbZp_UXo_WvqpRSpoosHsGtFiF4nFvxn0SuCefl2oNkUgvgFeRKzYPI&_rdr',
    },
    { name: 'instagram', path: 'https://www.instagram.com/lowisko_barcin/' },
    { name: 'tiktok', path: 'https://www.tiktok.com/@lowisko_barcin' },
    { name: '501-515-463', path: 'tel:+48501515463' },
  ];

  const navElements = elementsNav.map((element) => {
    // Sprawdź, czy element jest jednym z linków społecznościowych
    const isSocialLink = ['facebook', 'instagram', 'tiktok'].includes(
      element.name
    );

    return (
      <li className='navBar__item' key={element.name}>
        {isSocialLink ? (
          <a
            className='navBar__link'
            href={element.path}
            onClick={() => handleClick()}
          >
            {element.name}
          </a>
        ) : (
          <NavLink
            className='navBar__link'
            to={element.path}
            onClick={() => {
              handleClick();
              handleScroll();
            }}
          >
            {element.name}
          </NavLink>
        )}
      </li>
    );
  });

  return <ul className='navBar__items'>{navElements}</ul>;
};

export default MobileNavList;
