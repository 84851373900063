import React, { useState } from 'react';
import { Sling as Hamburger } from 'hamburger-react';

import MobileNavList from './MobileNavList';
import ImageLogo from './ImageLogo';

const MobileNav = ({ handleScroll }) => {
  const [isOpen, setOpen] = useState(false);
  const handleBurger = () => {
    setOpen((prevState) => !prevState);
  };

  if (isOpen) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }

  return (
    <>
      <div className='navMobile__logo'>
        <ImageLogo />
      </div>
      <div className='navMobile__hamburger'>
        <Hamburger toggled={isOpen} toggle={setOpen} />
      </div>
      <div className='navMobile__bar' style={{ right: isOpen ? '0' : '-100%' }}>
        <MobileNavList handleClick={handleBurger} handleScroll={handleScroll} />
      </div>
    </>
  );
};

export default MobileNav;
