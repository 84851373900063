import React from 'react';

import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';

const Tables = () => {
  const jan = 'Styczeń';
  const feb = 'Luty';
  const mar = 'Marzec';
  const apr = 'Kwiecień';
  const may = 'Maj';
  const jun = 'Czerwiec';
  const jul = 'Lipiec';
  const aug = 'Sierpień';
  const sep = 'Wrzesień';
  const oct = 'Październik';
  const nov = 'Listopad';
  const dec = 'Grudzień';
  const saturday = 'sobota';
  const sunday = 'niedziela';

  //   styczeń
  const sixthJan = '6. -wolne';
  const seventhJan = '7. -wolne';
  const thirteenthJan = '13. - wolne';
  const fourteenthJan = '14. - wolne';
  const twentythJan = '20. - wolne';
  const tFirstJan = '21. - wolne';
  const tseventhJan = '27. - wolne';
  const tEighthJan = '28. - wolne';
  //   luty
  const thirdFeb = '3. - wolne';
  const fourthFeb = '4. - wolne';
  const tFeb = '10. - wolne';
  const eleventhFeb = '11. - wolne';
  const seventeenthFeb = '17. - wolne';
  const eighthFeb = '18. - wolne';
  const tFourthFeb = '24. - wolne';
  const tFifthFeb = '25. - wolne';
  // marzec
  const secondMarch = '2.- wolne';
  const thirdMarch = '3. -wolne';
  const ninthMarch = '9. - wolne';
  const tenthMarch = '10. - wolne';
  const sixteenthMarch = '16. - wolne';
  const seventeenthMarch = '17. - wolne';
  const tThirdMarch = '23. - wolne';
  const tFourthMarch = '24. - wolne';
  const thirtiethMarch = '30. - wolne';
  const tOneMarch = '31. - wolne';
  // kwiecien
  const sixthApr = ' 6. -wolne';
  const seventhApr = ' 7. -Rozpoczęcie sezonu 2024 na łowisku Barcin 6h';
  const thirtheenthApr = ' 13. -wolne';
  const fourteenthApr = ' 14. -wolne';
  const twentiethApr = ' 20. -wolne';
  const tFirstApr = ' 21. -wolne';
  const tSeventhApr = ' 27. -wolne';
  const tEighthApr = ' 28. -wolne';
  // maj
  const fourthMay = ' 4. - wolne';
  const fifthMat = ' 5. - wolne';
  const eleventhMay = ' 11. - wolne';
  const twelfthMay = ' 12. - wolne';
  const eighteenMay =
    ' 18. - Wiosenny Puchar łowiska Barcin 2 tury/o bilet Super Method Masters 2024';
  const nineteenth =
    ' 19. - Wiosenny Puchar łowiska Barcin 2 tury/o bilet Super Method Masters 2024';
  const tFifthMay = ' 25. - zawody parowe OSMO';
  const tSixthMay = ' 26. - zawody parowe OSMO';

  // czerwiec

  const firstJun = '1. - wolne';
  const secondJun = '2. - GPC - Salon Wędkarski Osiniec';
  const eighthJun = '8. - wolne';
  const ninethJun = '9. - Meeting Trotka&Przyjaciele';
  const fiveteenthJun = '15. - wolne';
  const sixteenthJun = '16. - Polska Liga Method Feeder';
  const tSecondJun = '22. - Method challenge cup 2024';
  const tThirdJun = '23. - Method challenge cup 2024';
  const tNinethJun = '29. - wolne';
  const thirtiethJun = '30. - wolne';

  // lipiec

  const sixthJul = ' 6. - Zawody Parowe Barcin';
  const seventhJul = ' 7. - Zawody Parowe Barcin';
  const thirtheenthJul = ' 13. - wolne';
  const fourteenthJul = ' 14. - Zawody Antałex';
  const twentiethJul = ' 20. - wolne';
  const tFirstJul = ' 21. - wolne';
  const tSeventhJul = ' 27. - Krajowa Federacja Towarzystw Wędkarskich';
  const tEighthJul = ' 28. - Krajowa Federacja Towarzystw Wędkarskich';
  // sierpien

  const thirdAug = ' 3. - Zawody Nocne 12h';
  const fourthAug = ' 4. - Zawody Nocne 12h';
  const tenthAug = ' 10. - wolne';
  const eleventhAug = ' 11. - wolne';
  const seventeenthAug = ' 17. - wolne';
  const eighteenthAug = ' 18. - wolne';
  const tFourthAug = ' 24. - wolne';
  const tFifthAug = ' 25. - wolne';
  const thirtyFirstAug = ' 31. - wolne';

  // wrzesien

  const firstSep = ' 1. - wolne';
  const seventhSep = ' 7. - Matchpro Freestyle Carp Masters 2024';
  const eighthSep = ' 8. - Matchpro Freestyle Carp Masters 2024';
  const fourtennthSep = ' 14. - wolne';
  const fifeteenthSep = ' 15. - wolne';
  const tFirstSep = ' 21. - wolne';
  const tSecondSep = ' 22. - wolne';
  const tEighthSep = ' 28. - wolne';
  const tNinethSep = ' 29. - Pożegnanie Lata na Łowisku Barcin 6h';

  // pazdziernik

  const fifthOct = ' 5. - wolne';
  const sixthOct = ' 6. - wolne';
  const twelftOct = ' 12. - wolne';
  const thirteenthOct = ' 13. - wolne';
  const nineteenthOct = ' 19. - wolne';
  const twentiethOct = ' 20. - wolne';
  const tSixthOct = ' 26. - wolne';
  const tSeventhOct = ' 27. - wolne';

  // listopad

  const secondNov = ' 2. - wolne';
  const thirdNov = ' 3. - wolne';
  const ninethNov = ' 9. - wolne';
  const tenthNov = ' 10. - wolne';
  const sixteenthNov = ' 16. - wolne';
  const seventeenNov = ' 17. - wolne';
  const tThirdNov = ' . 23- wolne';
  const tFourthNov = ' 24. - wolne';
  const thirtiethNov = ' 30. - wolne';

  // grudzien

  const firstDec = ' 1. - wolne';
  const seventhDec = ' 7. - wolne';
  const eightDec = ' 8. - wolne';
  const fourteenthDec = ' 14. - wolne';
  const fifteenthDec = ' 15. - wolne';
  const tFirstDec = ' 21. - wolne';
  const tSecondDec = ' 22. - wolne';
  const tEightDec = ' 28. - wolne';
  const tNinethDec = ' 29. - wolne';

  const date = new Date();
  const month = date.getMonth();

  return (
    <Accordion defaultActiveKey={month.toString()} className='backgroundColor'>
      <Accordion.Item eventKey='0' className='backgroundColor'>
        <Accordion.Header className='backgroundColor'>{jan}</Accordion.Header>
        <Accordion.Body className='backgroundColor'>
          <Table striped bordered hover variant='dark'>
            <thead>
              <tr>
                <th>{saturday}</th>
                <th>{sunday}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{sixthJan}</td>
                <td>{seventhJan}</td>
              </tr>
              <tr>
                <td>{thirteenthJan}</td>
                <td>{fourteenthJan}</td>
              </tr>
              <tr>
                <td>{twentythJan}</td>
                <td>{tFirstJan}</td>
              </tr>
              <tr>
                <td>{tseventhJan}</td>
                <td>{tEighthJan}</td>
              </tr>
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey='1' className='backgroundColor'>
        <Accordion.Header className='backgroundColor'>{feb}</Accordion.Header>
        <Accordion.Body className='backgroundColor'>
          <Table striped bordered hover variant='dark'>
            <thead>
              <tr>
                <th>{saturday}</th>
                <th>{sunday}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{thirdFeb}</td>
                <td>{fourthFeb}</td>
              </tr>
              <tr>
                <td>{tFeb}</td>
                <td>{eleventhFeb}</td>
              </tr>
              <tr>
                <td>{seventeenthFeb}</td>
                <td>{eighthFeb}</td>
              </tr>
              <tr>
                <td>{tFourthFeb}</td>
                <td>{tFifthFeb}</td>
              </tr>
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey='2' className='backgroundColor'>
        <Accordion.Header className='backgroundColor'>{mar}</Accordion.Header>
        <Accordion.Body className='backgroundColor'>
          <Table striped bordered hover variant='dark'>
            <thead>
              <tr>
                <th>{saturday}</th>
                <th>{sunday}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{secondMarch}</td>
                <td>{thirdMarch}</td>
              </tr>
              <tr>
                <td>{ninthMarch}</td>
                <td>{tenthMarch}</td>
              </tr>
              <tr>
                <td>{sixteenthMarch}</td>
                <td>{seventeenthMarch}</td>
              </tr>
              <tr>
                <td>{tThirdMarch}</td>
                <td>{tFourthMarch}</td>
              </tr>
              <tr>
                <td>{thirtiethMarch}</td>
                <td>{tOneMarch}</td>
              </tr>
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey='3' className='backgroundColor'>
        <Accordion.Header className='backgroundColor'>{apr}</Accordion.Header>
        <Accordion.Body className='backgroundColor'>
          <Table striped bordered hover variant='dark'>
            <thead>
              <tr>
                <th>{saturday}</th>
                <th>{sunday}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{sixthApr}</td>
                <td>{seventhApr}</td>
              </tr>
              <tr>
                <td>{thirtheenthApr}</td>
                <td>{fourteenthApr}</td>
              </tr>
              <tr>
                <td>{twentiethApr}</td>
                <td>{tFirstApr}</td>
              </tr>
              <tr>
                <td>{tSeventhApr}</td>
                <td>{tEighthApr}</td>
              </tr>
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey='4' className='backgroundColor'>
        <Accordion.Header className='backgroundColor'>{may}</Accordion.Header>
        <Accordion.Body className='backgroundColor'>
          <Table striped bordered hover variant='dark'>
            <thead>
              <tr>
                <th>{saturday}</th>
                <th>{sunday}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{fourthMay}</td>
                <td>{fifthMat}</td>
              </tr>
              <tr>
                <td>{eleventhMay}</td>
                <td>{twelfthMay}</td>
              </tr>
              <tr>
                <td>{eighteenMay}</td>
                <td>{nineteenth}</td>
              </tr>
              <tr>
                <td>{tFifthMay}</td>
                <td>{tSixthMay}</td>
              </tr>
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey='5' className='backgroundColor'>
        <Accordion.Header className='backgroundColor'>{jun}</Accordion.Header>
        <Accordion.Body className='backgroundColor'>
          <Table striped bordered hover variant='dark'>
            <thead>
              <tr>
                <th>{saturday}</th>
                <th>{sunday}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{firstJun}</td>
                <td>{secondJun}</td>
              </tr>
              <tr>
                <td>{eighthJun}</td>
                <td>{ninethJun}</td>
              </tr>
              <tr>
                <td>{fiveteenthJun}</td>
                <td>{sixteenthJun}</td>
              </tr>
              <tr>
                <td>{tSecondJun}</td>
                <td>{tThirdJun}</td>
              </tr>
              <tr>
                <td>{tNinethJun}</td>
                <td>{thirtiethJun}</td>
              </tr>
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey='6' className='backgroundColor'>
        <Accordion.Header className='backgroundColor'>{jul}</Accordion.Header>
        <Accordion.Body className='backgroundColor'>
          <Table striped bordered hover variant='dark'>
            <thead>
              <tr>
                <th>{saturday}</th>
                <th>{sunday}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{sixthJul}</td>
                <td>{seventhJul}</td>
              </tr>
              <tr>
                <td>{thirtheenthJul}</td>
                <td>{fourteenthJul}</td>
              </tr>
              <tr>
                <td>{twentiethJul}</td>
                <td>{tFirstJul}</td>
              </tr>
              <tr>
                <td>{tSeventhJul}</td>
                <td>{tEighthJul}</td>
              </tr>
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey='7' className='backgroundColor'>
        <Accordion.Header className='backgroundColor'>{aug}</Accordion.Header>
        <Accordion.Body className='backgroundColor'>
          <Table striped bordered hover variant='dark'>
            <thead>
              <tr>
                <th>{saturday}</th>
                <th>{sunday}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{thirdAug}</td>
                <td>{fourthAug}</td>
              </tr>
              <tr>
                <td>{tenthAug}</td>
                <td>{eleventhAug}</td>
              </tr>
              <tr>
                <td>{seventeenthAug}</td>
                <td>{eighteenthAug}</td>
              </tr>
              <tr>
                <td>{tFourthAug}</td>
                <td>{tFifthAug}</td>
              </tr>
              <tr>
                <td>{thirtyFirstAug}</td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey='8' className='backgroundColor'>
        <Accordion.Header className='backgroundColor'>{sep}</Accordion.Header>
        <Accordion.Body className='backgroundColor'>
          <Table striped bordered hover variant='dark'>
            <thead>
              <tr>
                <th>{saturday}</th>
                <th>{sunday}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td>{firstSep}</td>
              </tr>
              <tr>
                <td>{seventhSep}</td>
                <td>{eighthSep}</td>
              </tr>
              <tr>
                <td>{fourtennthSep}</td>
                <td>{fifeteenthSep}</td>
              </tr>
              <tr>
                <td>{tFirstSep}</td>
                <td>{tSecondSep}</td>
              </tr>
              <tr>
                <td>{tEighthSep}</td>
                <td>{tNinethSep}</td>
              </tr>
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey='9' className='backgroundColor'>
        <Accordion.Header className='backgroundColor'>{oct}</Accordion.Header>
        <Accordion.Body className='backgroundColor'>
          <Table striped bordered hover variant='dark'>
            <thead>
              <tr>
                <th>{saturday}</th>
                <th>{sunday}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{fifthOct}</td>
                <td>{sixthOct}</td>
              </tr>
              <tr>
                <td>{twelftOct}</td>
                <td>{thirteenthOct}</td>
              </tr>
              <tr>
                <td>{nineteenthOct}</td>
                <td>{twentiethOct}</td>
              </tr>
              <tr>
                <td>{tSixthOct}</td>
                <td>{tSeventhOct}</td>
              </tr>
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey='10' className='backgroundColor'>
        <Accordion.Header className='backgroundColor'>{nov}</Accordion.Header>
        <Accordion.Body className='backgroundColor'>
          <Table striped bordered hover variant='dark'>
            <thead>
              <tr>
                <th>{saturday}</th>
                <th>{sunday}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{secondNov}</td>
                <td>{thirdNov}</td>
              </tr>
              <tr>
                <td>{ninethNov}</td>
                <td>{tenthNov}</td>
              </tr>
              <tr>
                <td>{sixteenthNov}</td>
                <td>{seventeenNov}</td>
              </tr>
              <tr>
                <td>{tThirdNov}</td>
                <td>{tFourthNov}</td>
              </tr>
              <tr>
                <td>{thirtiethNov}</td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey='11' className='backgroundColor'>
        <Accordion.Header className='backgroundColor'>{dec}</Accordion.Header>
        <Accordion.Body className='backgroundColor'>
          <Table striped bordered hover variant='dark'>
            <thead>
              <tr>
                <th>{saturday}</th>
                <th>{sunday}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td>{firstDec}</td>
              </tr>
              <tr>
                <td>{seventhDec}</td>
                <td>{eightDec}</td>
              </tr>
              <tr>
                <td>{fourteenthDec}</td>
                <td>{fifteenthDec}</td>
              </tr>
              <tr>
                <td>{tFirstDec}</td>
                <td>{tSecondDec}</td>
              </tr>
              <tr>
                <td>{tEightDec}</td>
                <td>{tNinethDec}</td>
              </tr>
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default Tables;
