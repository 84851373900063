import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from '../pages/Home';
import Competitions from '../pages/Competitions';
import Statute from '../pages/Statute';
import Gallery from '../pages/Gallery';
import Yt from '../pages/Yt';
import Cup from '../pages/Cup';

const Pages = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/zawody' element={<Competitions />} />
      <Route path='/wynikiPucharŁowiska' element={<Cup />} />
      <Route path='/regulamin' element={<Statute />} />
      <Route path='/galeria/*' element={<Gallery />} />
      <Route path='/youtube' element={<Yt />} />
    </Routes>
  );
};

export default Pages;
