import React from 'react';
import Event from '../Event';

const images = require.context(
  '../../../assets/images/gallery/2023/primaMethoda/photo/',
  false,
  /\.(jpg)$/
);
const thumb = require.context(
  '../../../assets/images/gallery/2023/primaMethoda/thumb/',
  false,
  /\.(jpg)$/
);
const CzapaIII = () => {
  return (
    <>
      <Event images={images} thumb={thumb}></Event>
    </>
  );
};

export default CzapaIII;
