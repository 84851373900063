import React from 'react';
import Events from '../components/gallery/Events';

const Gallery = () => {
  return (
    <div className='gallery'>
      <h2 className='gallery__title'>galeria</h2>
      <Events></Events>
    </div>
  );
};

export default Gallery;
