import React from 'react';
import Logo from '../assets/images/logo/logoBig.jpg';

const StartPage = ({ addClass }) => {
  const logo = Logo;
  return (
    <img
      src={logo}
      alt='logoStartPage'
      className={` ${addClass ? 'deactiveLogo' : ''}`}
    />
  );
};
export default StartPage;
