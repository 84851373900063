import React from 'react';

const Footer = () => {
  const map = (
    <iframe
      title='yt'
      src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d153781.39685631698!2d17.896679066060074!3d52.96939469565383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470367ba1a7634a9%3A0x52c7fc9e60619bb4!2sAndrebud!5e0!3m2!1spl!2spl!4v1712956016890!5m2!1spl!2spl'
      style={{}}
      width='200'
      height='200'
      allowfullscreen=''
      loading='lazy'
      referrerpolicy='no-referrer-when-downgrade'
    ></iframe>
  );
  const title = 'kontakt';
  const street = 'ul.Kościelna 58';
  const city = '88-190 Barcin';
  const telLink = 'tel:+48501515463';
  const tel = ' 501-515-463';
  const mailLink = 'mailto:lowisko_barcin@interia.pl';
  const mail = 'lowisko_barcin@interia.pl';
  const author = 'radoslawnowak@gmail.com';

  return (
    <>
      <div className='footer__leftSide'>
        <h3 className='footer__title'>{title}</h3>
        <p className='footer__text'>{street}</p>
        <p className='footer__text'>{city}</p>
        <p className='footer__text'>
          <a href={telLink} className='footer__link'>
            {tel}
          </a>
        </p>
        <p className='footer__text'>
          <a href={mailLink} className='footer__link'>
            {mail}
          </a>
        </p>
      </div>
      <div className='footer__rightSide'>{map}</div>
      <div className='footer__author'>{author}</div>
    </>
  );
};

export default Footer;
