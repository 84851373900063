import React from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';
import EventList from './EventList';

const handleScroll = () => {
  window.scrollTo(0, 0);
};
const Events = () => {
  const { eventsList } = EventList();
  const titleAlbum = 'ALBUM';
  const eventRoutes = eventsList.map((event, index) => (
    <Route key={index} path={event.path} element={event.page}></Route>
  ));
  const eventDivs = eventsList.map((event, index) => (
    <div
      key={index}
      className='gallery__link-container'
      style={{
        backgroundImage: `url(${event.backgroundImage})`,
      }}
    >
      <NavLink className='gallery__link' to={event.path} onClick={handleScroll}>
        <span>{event.title}</span>
        <span>{event.date}</span>
        <span>{titleAlbum}</span>
      </NavLink>
    </div>
  ));

  return (
    <div className='gallery__eventsList'>
      <Routes>{eventRoutes}</Routes>
      {eventDivs}
    </div>
  );
};

export default Events;
