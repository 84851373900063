import React from 'react';
import Points from '../components/statute/Points';

const Statute = () => {
  return (
    <div className='statutes'>
      <h2 className='home__subtitle'>regulamin</h2>
      <Points />
    </div>
  );
};

export default Statute;
